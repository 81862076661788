import { graphql, useStaticQuery } from "gatsby"

function use404Story() {
  const results = useStaticQuery(graphql`
    query Get404Story {
      storyblokEntry(field_component: {eq: "Page"}, name: {eq: "404"}) {
        content
        name
        uuid
        slug
        full_slug
      }
    }
  `)
  if(results.storyblokEntry) {
    return results.storyblokEntry
  }
  return null
}

export default use404Story