import * as React from "react"

import use404Story from "../hooks/use404Story"

import MainTemplate from "../templates/MainTemplate"


const NotFoundPage = () => {
  const story = use404Story()

  return ( <MainTemplate data={{storyblokEntry: story}} /> )
}

export default NotFoundPage
